import * as React from "react";
import { Helmet } from "react-helmet";

const Head = ({ title }) => {
  return (
    <Helmet>
      <title>{title || "DEFAULT TITLE"}</title>
    </Helmet>
  );
};

export default Head;
