import React from "react";
import Head from "./Head";
import Header from "./Header";
import Footer from "./Footer";
import Background from "./Background";
import ErrorBoundary from "./errors/ErrorBoundary";
import "./Layout.scss";

const Layout = ({ children }) => {
  return (
    <>
      <Background />
      <Head />
      <Header />
      <main id="content">
        <ErrorBoundary>{children}</ErrorBoundary>
      </main>
      <Footer />
    </>
  );
};
export default Layout;
