import * as React from "react";
import { Helmet } from "react-helmet";
import Link from "../Link";
import "./styles.scss";

const ErrorComponent = () => (
  <>
    <Helmet title="JC | Oops" />
    <section className="section error-container">
      <h3 className="error-message">Well that didn't work.</h3>
      <h4 className="error-message-subtitle">
        Shall we go back <Link to="/">home</Link>?
      </h4>
    </section>
  </>
);

export default ErrorComponent;
