import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link as GatsbyLink } from "gatsby";
// import Wave from "./../assets/wave.svg";
import Link from "./Link";

import "./Header.scss";

const Header = () => {
  const [isActive, setisActive] = React.useState(false);
  return (
    <>
      <header id="header">
        {/* <div id="wave-container">
          <Wave />
        </div> */}
        {/* MOBILE */}
        <nav
          className="navbar mobile-only"
          role="navigation"
          aria-label="main navigation"
        >
          <div className="navbar-brand">
            <a href="/" className="navbar-item">
              <div className="title-button">J.</div>
              {/* <img
                        src="https://bulma.io/images/bulma-logo.png"
                        alt="Logo"
                        width="112"
                        height="28"
                    /> */}
            </a>

            <div
              role="button"
              className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
              onClick={() => setisActive(!isActive)}
              onKeyPress={() => setisActive(!isActive)}
              tabIndex={0}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </div>
          </div>
          <div
            id="navbarBasicExample"
            className={`navbar-menu ${isActive ? "is-active" : ""}`}
          >
            <div className="navbar-end">
              <div className="navbar-item">
                <Link to="/" className="navbar-item" activeClassName="active">
                  Home
                </Link>
                <Link
                  to="/about"
                  className="navbar-item"
                  activeClassName="active"
                >
                  About
                </Link>
                <Link
                  to="/projects"
                  className="navbar-item"
                  activeClassName="active"
                >
                  Projects
                </Link>
                <Link
                  to="/contact"
                  className="navbar-item"
                  activeClassName="active"
                >
                  Contact
                </Link>
              </div>
            </div>
          </div>
        </nav>

        {/* NOT MOBILE */}
        <nav className="section level columns is-hidden-mobile">
          <div className="level-item has-text-centered">
            <Link to="/" className="is-info">
              Home
            </Link>
          </div>
          <div className="level-item has-text-centered">
            <Link to="/about" className="is-info">
              About
            </Link>
          </div>
          <div className="level-item has-text-centered">
            <GatsbyLink to="/">
              <StaticImage
                src="./../assets/logo.png"
                alt="logo"
                placeholder="blurred"
                loading={"eager"}
                style={{ borderRadius: "50%" }}
                width={50}
                height={50}
                imgClassName="logo-img"
              />
            </GatsbyLink>
          </div>
          <div className="level-item has-text-centered">
            <Link to="/projects" className="is-info">
              Projects
            </Link>
          </div>
          <div className="level-item has-text-centered">
            <Link to="/contact" className="is-info">
              Contact
            </Link>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;

/* <header class="text-gray-600 body-font">
  <div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
    <a class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        class="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full"
        viewBox="0 0 24 24"
      >
        <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
      </svg>
      <span class="ml-3 text-xl">Tailblocks</span>
    </a>
    <nav class="md:ml-auto md:mr-auto flex flex-wrap items-center text-base justify-center">
      <a class="mr-5 hover:text-gray-900">First Link</a>
      <a class="mr-5 hover:text-gray-900">Second Link</a>
      <a class="mr-5 hover:text-gray-900">Third Link</a>
      <a class="mr-5 hover:text-gray-900">Fourth Link</a>
    </nav>
    <button class="inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0">
      Button
      <svg
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        class="w-4 h-4 ml-1"
        viewBox="0 0 24 24"
      >
        <path d="M5 12h14M12 5l7 7-7 7"></path>
      </svg>
    </button>
  </div>
</header>; */
