import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import "./Link.scss";

const Link = ({ children, to, className }) => {
  return (
    <>
      <GatsbyLink
        to={to}
        className={`link ${className}`}
        activeClassName="active"
      >
        <span>{children}</span>
      </GatsbyLink>
    </>
  );
};

export default Link;
