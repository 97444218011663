import React from "react";
// import * as Sentry from "@sentry/browser";
import ErrorComponent from "./ErrorComponent";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    // Sentry.configureScope((scope) => {
    //   Object.keys(errorInfo).forEach((key) => {
    //     scope.setExtra(key, errorInfo[key]);
    //   });
    // });
    // Sentry.captureException(error);
  }

  render() {
    if (this.state.error) {
      return <ErrorComponent />;
    } else {
      return this.props.children;
    }
  }
}
