import * as React from "react";
import Icon from "./Icon";
import "./Footer.scss";

import Rss from "./../assets/icons/rss-square.svg";
import LinkedIn from "./../assets/icons/linkedin-square.svg";
import Email from "./../assets/icons/mail-sharp.svg";
import Github from "./../assets/icons/github.svg";

const Footer = () => {
  const [naked, setNaked] = React.useState(false);
  const removeStyles = () => {
    document
      .querySelectorAll('style,link[rel="stylesheet"]')
      .forEach((item) => item.remove());
    setNaked(true);
  };
  return (
    <>
      <footer className="text-gray-600 body-font">
        <div className="container flex flex-col flex-wrap px-5 py-24 mx-auto md:items-center lg:items-start md:flex-row md:flex-nowrap">
          <div className="flex-shrink-0 w-64 mx-auto mt-10 text-center md:mx-0 md:text-left md:mt-0">
            <div className="flex flex-row justify-start space-x-4 md:justify-center flex-nowrap">
              <a
                href="https://www.linkedin.com/in/carlsonjonathan/"
                target="_blank"
                rel="noreferrer"
              >
                <Icon>
                  <LinkedIn />
                </Icon>
              </a>
              <a
                href="https://github.com/jgcarlson"
                target="_blank"
                rel="noreferrer"
              >
                <Icon>
                  <Github />
                </Icon>
              </a>
              <a
                href={`mailto:${process.env.EMAIL}`}
                target="_blank"
                rel="noreferrer"
              >
                <Icon>
                  <Email />
                </Icon>
              </a>
              <a
                href={`${process.env.SITE_URL}/rss.xml`}
                target="_blank"
                rel="noreferrer"
              >
                <Icon>
                  <Rss />
                </Icon>
              </a>
              {/* <div className="">dev.to profile?</div>
          <div className="">xing profile?</div> */}
            </div>

            <p className="mt-2 text-sm text-gray-500">
              Thanks for scrolling all the way down here!
            </p>
            <p className="mt-2 text-sm text-gray-500">
              © {new Date().getFullYear()} and all that.
            </p>
          </div>
          <div className="flex flex-wrap flex-grow order-first -mb-10 text-center md:pr-20 md:text-left">
            <div className="w-full px-4 lg:w-1/4 md:w-1/2">
              <h2 className="mb-3 text-sm font-medium tracking-widest text-gray-900 title-font">
                COLUMN 1
              </h2>
              <nav className="mb-10 list-none">
                <li>
                  <a
                    href="/"
                    className="text-sm text-gray-600 hover:text-gray-800"
                  >
                    Resume?
                  </a>
                </li>
                {naked || (
                  <li>
                    <button
                      className="text-sm text-gray-600 hover:text-gray-800"
                      onClick={removeStyles}
                    >
                      Naked
                    </button>
                  </li>
                )}
                <li>
                  <a
                    href="/"
                    className="text-sm text-gray-600 hover:text-gray-800"
                  >
                    {/* Link to joke privacy policy? (eg https://alistairshepherd.uk/privacy/) */}
                    Privacy Policy
                  </a>
                </li>
              </nav>
            </div>
            <div className="w-full px-4 lg:w-1/4 md:w-1/2">
              <h2 className="mb-3 text-sm font-medium tracking-widest text-gray-900 title-font">
                COLUMN 2
              </h2>
              <nav className="mb-10 list-none">
                <li>
                  <a
                    className="text-sm text-gray-600 hover:text-gray-800"
                    href="/"
                  >
                    First Link
                  </a>
                </li>
                <li>
                  <a
                    className="text-sm text-gray-600 hover:text-gray-800"
                    href="/"
                  >
                    Second Link
                  </a>
                </li>
              </nav>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
